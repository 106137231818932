.mini-swiper{
    max-width: 320px;
    max-height: 218px;
    overflow: hidden;
    position: relative;
    
}


.mini-swiper img{
    width: 100%;
    height: auto;
}

.mini-swiper .swiper-slide{
    overflow: hidden;
}

.mini-inner-swiper{
    max-width: 320px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 50px;
    box-sizing: border-box;
}

.mini-inner-swiper .swiper{
    width: 100%;
    box-sizing: border-box;
    caret-color: transparent;
}



.swiper-mini-container{
    position: relative;
    margin-top: 45px;
}
.mini-inner-swiper .swiper-slide-thumb-active{
    border: 3px solid #00A4DC;
    height: 100%;
    width: 100%;
}

.mini-inner-swiper .swiper-slide{
    height: 90px;
    width: 111px;
}
.mini-inner-swiper img{
    width: 100%;
    height: 100%;
    display: block;
}

.detail-prev,
.detail-next{
    position: absolute;
    z-index: 5555;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    caret-color: transparent;
}
.detail-next{
    top: 45%;
    right: 0;
    margin-right: 10px;
}

.detail-prev{
    top: 45%;
    left: 0;
    margin-left: 10px;
}



.slider-float-box{
    float: left;
    padding-right: 30px;
    padding-bottom: 10px;
}


@media screen and (min-width: 390px){
    .mini-swiper{
        max-width: 350px;
        max-height: 220px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 350px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 100px;
        width: 100px;
    }
}



@media screen and (min-width: 410px){
    .mini-swiper{
        max-width: 370px;
        max-height: 257px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 370px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 81px;
        width: 111px;
    }
}


@media screen and (min-width: 440px){
    .mini-swiper{
        max-width: 400px;
        max-height: 257px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 400px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 81px;
        width: 111px;
    }
}

@media screen and (min-width: 480px){
    .mini-swiper{
        max-width: 450px;
        max-height: 257px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 450px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 81px;
        width: 111px;
    }
}


@media screen and (min-width: 540px){
    .mini-swiper{
        max-width: 500px;
        max-height: 257px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 500px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 81px;
        width: 111px;
    }
}

@media screen and (min-width: 640px){
    .mini-swiper{
        max-width: 585px;
        max-height: 343px;
        overflow: hidden;
        position: relative;

    }

    .mini-inner-swiper{
        max-width: 585px;
        height: 81px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;

    }
    .mini-inner-swiper .swiper-slide{
        height: 81px;
        width: 111px;
    }
}
