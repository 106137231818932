.goal-img {
    width: 182px;
    height: 193px;
    margin-right: 20px;
}

.goal-description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}

.goal-description p{
    font-family: 'Montserrat', sans-serif !important;
}
.map {
    position: relative;
    width: 100%;
    display: flex;
    height: 400px;

}

.map-img-wrapper {
    max-width: 1216px;
    width: 100%;
    height: 596px;
    position: relative;
}

.map-img {
    display: block;
    max-width: 1216px;
    width: 100%;
    position: relative;
}

.projects{
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.projects p:first-of-type {
    font-weight: bold;
}

.map-details {
    position: absolute;
    top: 62%;
    right: 0;
}

.map-detail {
    border: 2px solid #00A4DC;
  width: 300px;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #00A4DC;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.map-detail p {
    margin: 0;
    padding: 0;
}
.popup{
    position: absolute;
    top: 42%;
    right: 1%;
    /*border: 1px solid #00A4DC;*/
    padding: 10px 30px;
    color: #088cb9;
    text-transform: capitalize;
    font-size: 18px;
    background-color: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
    z-index: 1;
}


.circle-pin {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    border:2px solid #088cb9;
    color: #00A4DC;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#bishkek {
    position: absolute;
    top: 2%;
    left: 42%;
}

#chui {
    position: absolute;
    top: 3%;
    left: 47%;
}

#naryn {
    position: absolute;
    top: 16%;
    left: 55%;
}

#batken {
    position: absolute;
    top: 30%;
    left: 15%;
}

#oshCity {
    position: absolute;
    top: 27%;
    left: 35%;
}

#osh {
    position: absolute;
    top: 30%;
    left: 31%;
}

#talas {
    position: absolute;
    top: 5%;
    left: 24%;
}

#jalal {
    position: absolute;
    top: 15%;
    left: 32%;
}

#kol {
    position: absolute;
    top: 11%;
    left: 79%;
}

@media screen and (min-width: 710px){

    .map-detail{
        width: 405px;
    }

    #bishkek {
        position: absolute;
        top: 2%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 3%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 16%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 35%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 27%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 30%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 5%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 15%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 11%;
        left: 79%;
    }
}


@media screen and (min-width: 800px){

    .map {
        position: relative;
        width: 100%;
        display: flex;
        height: 596px;

    }

    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 2%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 3%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 16%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 37%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 31%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 35%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 5%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 15%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 11%;
        left: 79%;
    }


}


@media screen and (min-width: 800px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 2%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 3%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 21%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 40%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 31%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 35%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 7%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 21%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 11%;
        left: 79%;
    }

}


@media screen and (min-width: 880px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 2%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 3%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 21%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 43%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 34%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 38%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 7%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 21%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 11%;
        left: 79%;
    }

}


@media screen and (min-width: 950px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 2%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 3%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 21%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 46%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 40%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 44%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 7%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 21%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 11%;
        left: 79%;
    }

}


@media screen and (min-width: 950px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 3%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 6%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 24%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 51%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 44%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 48%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 10%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 24%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 14%;
        left: 79%;
    }

}

@media screen and (min-width: 1100px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 4%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 8%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 28%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 54%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 46%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 50%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 10%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 26%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 14%;
        left: 79%;
    }

}


@media screen and (min-width: 1200px){
    .circle-pin {
        width: 42px;
        height: 42px;
        border: 5px solid #00A4DC;
        border-radius: 50%;
        background-color: white;
        color: #00A4DC;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bishkek {
        position: absolute;
        top: 4%;
        left: 42%;
    }

    #chui {
        position: absolute;
        top: 8%;
        left: 47%;
    }

    #naryn {
        position: absolute;
        top: 28%;
        left: 55%;
    }

    #batken {
        position: absolute;
        top: 62%;
        left: 15%;
    }

    #oshCity {
        position: absolute;
        top: 52%;
        left: 35%;
    }

    #osh {
        position: absolute;
        top: 56%;
        left: 31%;
    }

    #talas {
        position: absolute;
        top: 10%;
        left: 24%;
    }

    #jalal {
        position: absolute;
        top: 26%;
        left: 32%;
    }

    #kol {
        position: absolute;
        top: 18%;
        left: 79%;
    }

}




.ministry-swiper-container {
    position: relative;
}

.ministry-swiper-container .mySwiper {
    padding: 10px 5px;
}


.ministry-swiper-container .swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    width: 50%;
}

.ministry-swiper-container .navigation-btns-p {
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.ministry-swiper-container .swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.ministry-swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #00A4DC;
}

.ministry-swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}


.ministry-swiper-container .swiper-button-prev-ministry,
.ministry-swiper-container .swiper-button-next-ministry {
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #00A4DC;
    color: white;
}

.ministry-swiper-container .swiper-button-disabled {
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
}

.international-box {
    background-size: contain;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: overlay;
    height: auto;
    width: 100%;
    min-height: 361px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

.international-box h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: #beb6b6;
    text-align: center;
    padding: 15px 10px;
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: auto;
}

.ministry-box h4,
.international-box h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    color: white;
    text-align: center;
    padding: 25px 10px 0;
    margin: 0;
}


.international-swiper-container {
    position: relative;
}

.international-swiper-container .mySwiper {
    padding: 10px 5px;
}


.international-swiper-container .swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    width: 50%;
}

.international-swiper-container .navigation-btns-p {
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.international-swiper-container .swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.international-swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #00A4DC;
}

.international-swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}


.international-swiper-container .swiper-button-prev-international,
.international-swiper-container .swiper-button-next-international {
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #00A4DC;
    color: white;
}

.international-swiper-container .swiper-button-disabled {
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
}

.international-box {
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
    height: auto;
    width: 100%;
    min-height: 361px;
    background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.1))
}