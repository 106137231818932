.contact-form{
box-sizing: border-box;
    margin-bottom: 50px;
}


.contact-form h5{
    font-size: 16px;
}

.contact-form-p{
    color: #55606E;
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
}

.line{
    border-bottom: 2px solid #EDEFF0;
}

.blue-line{
    border-bottom: 2px solid #00A4DC;
    max-width: 249px;
}

.goal-blue-line{
    border-bottom: 2px solid #00A4DC;
    max-width: 350px;
}

.contact-input{
    border: 1px solid #00A4DC;
    background-color: #F5F5F5;
    width: 100%;
    height: 42px;
    padding-left: 10px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 22px;
    box-sizing: border-box;
}

.contact-input:focus {
    border: 2px solid #00ADEF;
    outline: none;
}

.contact-input::placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #55606E;
    font-family: 'Montserrat', sans-serif;
}


.contact-input-textarea{
    resize: none;
    padding: 10px;
    width: 100%;
    background: #F5F5F5;
    min-height: 112px;
    font-family: 'Montserrat', sans-serif;
    color: #55606E;
    box-sizing: border-box;
    border: 1px solid #00A4DC;
}

.contact-input-textarea:focus{
    border: 2px solid #00A4DC;
    outline: none;
}

.file-icon{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 50px;
}

.file-icon-wrapper{
    width: 44px;
    height: 44px;
border-radius: 50%;
    background-color: #F5F5F5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.file-icon-wrapper img{
    width: 23px;
    height: 19px;
}

.file-p{
    font-size: 13px;
    color: #55606E;
}

.file-upload{
    border: 2px dashed #00A4DC;
    background-color: #F5F5F5;
    min-height: 154px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.file-upload img{
    width: 50px;
}
.file-upload-p {
    font-weight: 400;
    font-size: 14px;
    color: #696969D5;
}

.file-upload button{
    border: none;
    background-color: transparent;
    color: #00A4DC;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #00A4DC;
    cursor: pointer;
    margin-bottom: 10px;
}

.extension{
    font-weight: 700;
    font-family: Ubuntu, sans-serif;
    font-size: 13px;
    color: #232327;
    margin: 10px 0 0 0;
}

.uploaded{
    font-weight: 700;
    font-family: Ubuntu, sans-serif;
    font-size: 13px;
    color: #232327;
    margin: 10px 0 20px 0;
}

.agree-label{
    font-size: 13px;
    caret-color: transparent;
    margin-left: 7px;
}
input[type="checkbox"]{
visibility: hidden;
    margin-left: -17px;
}

.blue-main-btn{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #00A4DC;
   height: 50px;
   width: 300px ;
    border: none;
    font-weight: 600;
    caret-color: transparent;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 16px;

}

.blue-main-btn:hover{
    background: linear-gradient(90deg, rgba(0,164,220,1) 0%, rgba(13,105,136,1) 41%, rgba(0,164,220,1) 100%);
}


