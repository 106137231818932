
*,
*:before,
*:after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


body{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.search-goal-ic{
    width: 70px;
    height: auto;
    margin-right: 5px;
}