.uni-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 108px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    margin: 5px;
    width: 100%;
    padding: 0 32px;
}

.uni-card h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.uni-card a{
    color: #00A4DC;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;

}