
.links-block{
    padding: 32px 0;
    background: #00A4DC;
}

.link-box{
    width: 100%;
    height: 100%;
    text-align: center;
    background: white;
    padding: 16px 10px;
}

.link-box p{
font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #424242;
}

.contact-btn{

}