.footer{
    background: #FAFAFA;
    caret-color: transparent;
}

.footer-logo-link{
    text-transform: uppercase;
    text-decoration: none;
    font-size: 11px;
    color: #00A4DC;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    width: 80%;
    margin-left: 10px;
}

@media screen and (min-width: 700px) {
    .footer-logo-link{
        font-size: 14px;
        width: 55%;
    }
}

.contact-info{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #55606E;
    font-weight: 400;
}

.social-network-icons{
    display: flex;
    justify-content: space-between;
}

.social-network-icons a{
    text-decoration: none;
    display: inline-block;
}

.social-network-icons img{
    display: block;
}