.show-more-btn{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #00A4DC;
    border: none;
    background: transparent;
    text-decoration: none;
}

.block-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin: 22px 0;
}

.news-line{
    max-width: 80px;
}

.news-swiper-container{
    position: relative;
    margin-bottom: 30px;
}

.swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    width: 50%;
}

.hide {
    display: none;
}

.navigation-btns-p{
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #00A4DC;
}
.swiper-pagination .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
}


.swiper-button-prev-news,
.swiper-button-next-news
{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #00A4DC;
    color: white;
}

.swiper-button-disabled{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
}