.chart-box {
    width: 100%;
    height: 400px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: relative;
    padding: 5px 15px 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.bigger{
    height: 430px;
    padding-bottom: 30px;
}

.chart-title {
    position: absolute;
    top: 10px;
    left: 26px;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: black;
    max-width: 60%;
    padding-right: 10px;
}

@media screen and (min-width: 700px) {
    .chart-title {
        position: absolute;
        top: 18px;
        left: 36px;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        color: black;
        max-width: 70%;
    }
}

@media screen and (min-width: 820px) {
    .chart-title {
        position: absolute;
        top: 15px;
        left: 26px;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        color: black;
        max-width: 66%;
    }
}


@media screen and (min-width: 920px) {
    .chart-title {
        position: absolute;
        top: 18px;
        left: 26px;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        color: black;
        max-width: 65%;
    }
}

@media screen and (min-width: 1150px) {
    .chart-title {
        position: absolute;
        top: 18px;
        left: 26px;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        color: black;
        max-width: 70%;
    }
}