.map-box {
    padding: 28px 40px 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-box h4 {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: black;
    max-width: 309px;
    position: absolute;
    top: 3%;
    left: 3%;
    font-weight: 400;
    margin-top: 0;

}

.mapImg {
    display: block;
   width: 100%;
    height: auto;
}

.map-box a {
    color: #00A4DC;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid #00A4DC;
    position: absolute;
    top: 80%;
    right: 5%;
}

@media screen and (min-width: 700px) {
    .map-box a {
        color: #00A4DC;
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        border-bottom: 1px solid #00A4DC;
        position: absolute;
        top: 80%;
        right: 5%;
    }
}

.arrow-map-img{
    margin-left: 5px;
    display: block;
}

@media screen and (min-width: 1000px) {

    .mapImg{
        display: block;
        max-width: 866px;
        height: auto;
    }

    .map-box h4 {
        margin-top: 10px;

    }

}


