.tabs-parent{
    background-color: #F2F6F9;
    font-family:'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #00A4DC;
    align-items: center;
    text-align: center;
    caret-color: transparent;
    margin-bottom: 50px;
}

.active-tab{
    background-color: #00A4DC;
    color: white;
}

.tabs-filter{
    padding: 10px 0;
    cursor: pointer;
    height: 38px;
}

.aim-select{
    border: 1px solid #55606E80;
    font-size: 14px;
    color: #55606E;
    font-family: 'Montserrat', sans-serif;
    height: 50px;
    width: 100%;
}

.goal{
    font-size: 14px;
    white-space: pre-wrap;
}



.search-btn{
    width: 300px;
    margin-top: 0;
    align-self: end;
}

.filter-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #55606E;
    margin-bottom: 16px;
}

