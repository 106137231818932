.goals-swiper-container{
    position: relative;
}
.goals-swiper-container .mySwiper{
    padding: 10px 5px;
}


.goals-swiper-container .swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    /*width: 50%;*/
}

.goals-swiper-container .navigation-btns-p{
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.goals-swiper-container .swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.goals-swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #00A4DC;
}
.goals-swiper-container .swiper-pagination .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
}


.goals-swiper-container .swiper-button-prev-goal,
.goals-swiper-container .swiper-button-next-goal
{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #00A4DC;
    color: white;
}

.goals-swiper-container .swiper-button-disabled{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
}