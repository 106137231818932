.aim{
    max-width: 100%;
    line-height: 17px;
    border: 1px solid #00A4DC;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
}

.aim p{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #00A4DC;
    margin: 0;
    font-weight: 500;
}