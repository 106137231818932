.main-block{
    background-image: url("../../assets/images/connected-hands.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
    padding: 52px 0 45px;
    background-size: cover;
    margin-bottom: 10px;
}

.title-wrapper{
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    caret-color: transparent;
    margin-bottom: 32px;
}
.title-wrapper h3{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    max-width: 305px;
    color: white;
    text-align: center;
}

.aims-box-parent{
    caret-color: transparent;
}
.aims-box-parent img{
    width: 100%;
    height: auto;
}
