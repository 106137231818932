.lib-card{
    padding: 32px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    margin: 32px 0;
    position: relative;
}

.lib-wrapper{
    max-width: 293px;
   height: 261px;
    margin-bottom: 22px;
    caret-color: transparent;
}

.lib-wrapper img{
    width: 100%;
    height: 100%;
    display: block;

}

.lib-card h5{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.lib-card p{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #55606E;
    margin-bottom: 10px;
}

.download-btn{
    border: none;
    background: transparent;
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    caret-color: transparent;
    cursor: pointer;
}

.down-arrow{
    position: absolute;
    top:-10px;
    right: 3.5px;
    transition-duration: 0.1s;
}

.download-btn-div:hover .down-arrow{
    top:-20px;
    right: 3.5px;
}